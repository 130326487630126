<template>

    <div>

    <b-row class="match-height">
      <b-col         lg="6"
>
        <b-card>

            <severity-chart />
      </b-card>

      </b-col>
      
    </b-row>


    
  </div>


</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,BRow, BCol 
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import SeverityChart from '././juno_screens/SeverityChart.vue'





export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    SeverityChart
},
  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

//hides gridelines by the chart --juno fix
.apexcharts-gridline {
    stroke-width: 0px;
}
</style>
